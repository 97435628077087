// src/router/index.js
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Home', component: () => import('@/view/Home/Home.vue') },
    { path: '/movie',name: 'Homea', component: () => import('@/view/Home/Home.vue') },
]

const router = new VueRouter({
    routes,
    mode: 'history' // 如果你想用history模式而不是默认的hash模式，请确保服务器配置正确
})

export default router