import Vue from 'vue'
import App from './App.vue'
import dataV from '@jiaminghi/data-view'
import router from './router'
import './core/elementLazy'

Vue.use(dataV)
Vue.config.productionTip = false

new Vue({
  router, // 注册路由
  render: h => h(App),
}).$mount('#app')

