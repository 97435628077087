<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>

<style>
*{
  margin: 0;
  padding: 0;
  list-style: none;
}
html{
  font-size: 10px;
}
#app{
  width: 99vw;
  height: 100vh;
}
</style>
